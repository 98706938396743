import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { CoverSheet } from '../../model/cover-sheet';

@Component({
    standalone: true,
    selector: 'oiv-viewer-cover-sheet',
    templateUrl: './cover-sheet.component.html',
    styleUrls: ['./cover-sheet.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverSheetComponent {
    coverSheet = input<CoverSheet>({
        title: '',
        info: '',
    });
}
